import React, { useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LOGOUT_URL } from '../apiConfig';

import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const SupervisorNavbar = () => {
    const [showModal, setShowModal] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };

    const handleLogout = async () => {
        // Limpiar el token del localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('selectedDate');
        localStorage.removeItem('isSupervisor')
        try {
            await axios.post(LOGOUT_URL, null, { headers });

            // Redirigir a la página de login
            navigate('/');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };
    const confirmLogout = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleConfirmLogout = () => {
        handleLogout();
        setShowModal(false);
    }
    return (
        <>
        <nav className={`navbar navbar-expand-lg ${theme === "light" ? "navbar-light bg-light" : "navbar-dark bg-dark"}`}>
            <div className="container">
                <NavLink className="navbar-brand" >
                    La Milkiforma
                </NavLink>
                <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav2"
                        aria-controls="navbarNav2"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarNav2">
                    <ul className="navbar-nav me-auto"> {/* Cambiado 'mr-auto' a 'me-auto' para la versión actual de Bootstrap */}
                      <li className="nav-item mx-3">
                    <NavLink className="nav-link" to="/supervision/resumen" activeClassName="active">
                                    Resumen
                                </NavLink>
                            </li>
                        <li className="nav-item mx-3">
                            <NavLink to="/supervision/user-management" className="nav-link" activeClassName="active">Gestión de Usuarios
                            </NavLink>
                        </li>
                    </ul>
                    <div className="d-flex ms-auto"> {/* 'ms-auto' asegura que el contenido se empuje a la derecha */}

<                   button className="btn btn-outline-danger btn-logout" 
                    onClick={confirmLogout}>
                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                            Logout
                    </button>     
                                       </div>

                </div>
            </div>
        </nav>
        <Modal 
            show={showModal} 
            onHide={handleClose}    
            centered
            className={`${theme === 'dark' ? 'modal-dark' : 'modal-light'} custom-modal`} // Clase personalizada
            >
                <Modal.Header 
                    closeButton
                    className={`${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'} custom-modal-header`} // Añadir clase
                >
                    <Modal.Title>Confirmar Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
                    ¿Estás seguro que deseas cerrar sesión?
                </Modal.Body>
                <Modal.Footer className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleConfirmLogout}>
                        Cerrar Sesión
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SupervisorNavbar;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PROFILE_URL, UPDATE_PROFILE_URL } from '../apiConfig';
import { TailSpin } from 'react-loader-spinner';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [studentDetails, setStudentDetails] = useState({
    niche: '',
    prosp_country: '',
    script: '',
    service_offer: '',
    observations: '',
    timezone: 'UTC'  // Valor predeterminado
  });
  
  const [error, setError] = useState(null); // Estado de error
  const [successMessage, setSuccessMessage] = useState(null); // Para mostrar mensaje de éxito
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const confirmToggleTheme = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleConfirmToggleTheme = () => {
    toggleTheme()
    setShowModal(false);
  };


  const fetchUpdatedData = async () => {
    setError(null);
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No autorizado');
        return;
      }

      const headers = { Authorization: `Token ${token}` };
      const response = await axios.get(PROFILE_URL, { headers });
      const updatedData = response.data.student_details;
      const timezone = response.data.timezone
      console.log(response.data);

      // Actualiza el estado con los datos recibidos
      setStudentDetails({
        niche: updatedData.niche || '',
        prosp_country: updatedData.prosp_country || '',
        script: updatedData.script || '',
        service_offer: updatedData.service_offer || '',
        observations: updatedData.observations || '',
        timezone: timezone || '',
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
          console.error('Unauthorized, removing token');
          localStorage.removeItem('token'); // Eliminar token si es un 401
          alert('Debes iniciar sesión nuevamente.');
          window.location.href = '/'; // Redirigir a /login
  
      } else {
      setError('Error al obtener los datos del perfil.');
    }} finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentDetails({
      ...studentDetails,
      [name]: value
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage(null);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Token ${token}` };
      const response = await axios.put(PROFILE_URL, studentDetails, { headers });
      setSuccessMessage('Datos actualizados con éxito');
    } catch (error) {
      if (error.response && error.response.status === 401) {
          console.error('Unauthorized, removing token');
          localStorage.removeItem('token'); // Eliminar token si es un 401
          alert('Debes iniciar sesión nuevamente.');
          window.location.href = '/'; // Redirigir a /login
        } else {
      setError('Error al actualizar el perfil.');
    }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpdatedData(); // Llama a la función para obtener los datos una vez montado el componente
  }, []);


  useEffect(() => {
    localStorage.setItem('theme', theme); // Guarda el tema en localStorage
    document.body.className = theme; // Aplica la clase 'light' o 'dark' al body
    
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light'); // Cambia entre light y dark
    navigate(0)
  };

  // Obtener todas las zonas horarias
  const timezones = moment.tz.names();

  return (
    <div className="container mt-4">
      <h1>Perfil de Usuario</h1>
      {error && <p className="text-danger">{error}</p>}
      {successMessage && <p className="text-success">{successMessage}</p>}
      {loading ?  (
                    <div className="d-flex justify-content-center mt-3">
                        <TailSpin color="#007bff" height={50} width={50} />
                    </div>
                        ) : (
                    <div>
            <form onSubmit={handleFormSubmit}>
        <div className="mb-3">
          <label htmlFor="niche" className="form-label">Nicho</label>
          <input
            type="text"
            className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="niche"
            name="niche"
            value={studentDetails.niche}
            onChange={handleInputChange}
            placeholder="Ingrese su nicho"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="prosp_country" className="form-label">País de Prospección</label>
          <input
            type="text"
            className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="prosp_country"
            name="prosp_country"
            value={studentDetails.prosp_country}
            onChange={handleInputChange}
            placeholder="Ingrese su país de prospección"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="script" className="form-label">Script</label>
          <textarea
            className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="script"
            name="script"
            rows="3"
            value={studentDetails.script}
            onChange={handleInputChange}
            placeholder="Ingrese su script"
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="service_offer" className="form-label">Oferta de Servicio</label>
          <textarea
            className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="service_offer"
            name="service_offer"
            rows="3"
            value={studentDetails.service_offer}
            onChange={handleInputChange}
            placeholder="Ingrese su oferta de servicio"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="observations" className="form-label">Observaciones o Comentarios</label>
          <textarea
            className={`form-control mb-3 ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="observations"
            name="observations"
            rows="3"
            value={studentDetails.observations}
            onChange={handleInputChange}
            placeholder="Ingrese observaciones o comentarios"
          ></textarea>

        <div className="mb-3">
          <label htmlFor="timezone" className="form-label">Tu zona horaria</label>
          <select
            className={`form-control w-25 ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="timezone"
            name="timezone"
            value={studentDetails.timezone}
            onChange={handleInputChange}
          >
            {timezones.map((tz) => (
              <option key={tz} value={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>

        </div>

        <button type="submit" className="btn btn-primary">Guardar Cambios</button>
      </form> 
            <div className="theme-toggle">
                            <FontAwesomeIcon icon={faSun} size="2x" 
                            className={`m-2 ${theme === "light" ? "" : "text-secondary"}`}
                            />
                            <input
                                type="checkbox"
                                id="toggle"
                                // className="toggle-checkbox"
                                className={`toggle-checkbox ${theme === "light" ? "" : "text-secondary"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}

                                checked={theme === 'dark'}
                                onChange={confirmToggleTheme} // Al cambiar el estado, alternar el tema
                            />
                            <label htmlFor="toggle" className="toggle-label">
                                <span className="toggle-icon"></span>
                            </label>
                            <FontAwesomeIcon icon={faMoon} size="2x" 
                                className={`m-2  ${theme === "light" ? "" : "text-secondary"}`}
                            />
                      </div>
                    </div>
                  )
                }
                <Modal 
                  show={showModal} 
                  onHide={handleClose} 
                  centered
                  className={`${theme === 'dark' ? 'modal-dark' : 'modal-light'} custom-modal`} // Clase personalizada
                  >
                    <Modal.Header 
                    closeButton 
                    className={`${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'} custom-modal-header`} // Añadir clase
                    >
                        <Modal.Title>Confirmar Cambio de tema</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
                        ¿Estás seguro que deseas cambiar el tema? <br /> Se recargará la página.
                    </Modal.Body>
                    <Modal.Footer className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={handleConfirmToggleTheme}>
                            Cambiar tema
                        </Button>
                    </Modal.Footer>
                </Modal>
        
    </div>
    
  );
};

export default Profile;

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STUDENT_DATA_URL } from '../apiConfig';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const StudentDetailsModal = ({ selectedStudent, studentDetails, handleClose, setStudentDetails, fetchStudentData }) => {
    const [newObservation, setNewObservation] = useState('');
    const [editingObservation, setEditingObservation] = useState(null);
    const [editedObservationText, setEditedObservationText] = useState('');
    const [loading, setLoading] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    
    const handleSubmit = () => {
        if (newObservation) {
            handleAddObservation(newObservation); // Llamar a la función del componente padre
            setNewObservation(''); // Limpiar input después de agregar
        }
    };
    // Función para iniciar la edición de una observación
    const startEditObservation = (observation) => {
        setEditingObservation(observation.id); // Establecer la observación que está siendo editada
        setEditedObservationText(observation.observations); // Cargar el texto de la observación
    };

    const stopEditObservation = () => {
        setEditingObservation(null); // Establecer la observación que está siendo editada
        setEditedObservationText(''); // Cargar el texto de la observación
    };

    const handleAddObservation = async (newObservation) => {
        try {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Token ${token}` };
            setLoading(true);
            await axios.post(STUDENT_DATA_URL, {
                student_id: selectedStudent,
                observation: newObservation,
            }, { headers });
            // Actualiza los detalles del estudiante
            fetchStudentData(selectedStudent);
        } catch (error) {
            console.error('Error adding observation:', error);
        }
        finally{
            setLoading(false);
        }
    };
    // Función para guardar la observación editada
    const handleSaveEditedObservation = async () => {
        try {
            if (!editedObservationText) return;
            setLoading(true);

            // Hacer una solicitud PUT para actualizar la observación en el backend
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Token ${token}` };
            await axios.put(`${STUDENT_DATA_URL}${editingObservation}/`, {
                observations: editedObservationText
            }, { headers });

            // Actualizar la lista de observaciones después de la edición
            const updatedObservations = studentDetails.observations.map(obs =>
                obs.id === editingObservation ? { ...obs, observations: editedObservationText } : obs
            );
            setStudentDetails({ ...studentDetails, observations: updatedObservations });

            setEditingObservation(null); // Finalizar la edición
            setEditedObservationText(''); // Limpiar el input
        } catch (error) {
            console.error('Error al editar la observación:', error);
        }
        finally{
            setLoading(false);

        }
    };


        // Función para eliminar una observación
    const handleDeleteObservation = async (observationId) => {
        try {
            // Confirmar eliminación
            if (!window.confirm('¿Estás seguro de que deseas eliminar esta observación?')) return;

            setLoading(true);

            // Hacer una solicitud DELETE para eliminar la observación en el backend
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Token ${token}` };
            await axios.delete(`${STUDENT_DATA_URL}${observationId}/`, { headers });

            // Eliminar la observación de la lista en el frontend
            const updatedObservations = studentDetails.observations.filter(obs => obs.id !== observationId);
            setStudentDetails({ ...studentDetails, observations: updatedObservations });
        } catch (error) {
            console.error('Error al eliminar la observación:', error);
        }
        finally{
            setLoading(false);

        }
    };

    return (
        <Modal show onHide={handleClose}
            className={`${theme === 'dark' ? 'modal-dark' : 'modal-light'} custom-modal`} // Clase personalizada
>
            <Modal.Header   
                closeButton
                className={`custom-modal-header custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`} // Añadir clase
>
                <Modal.Title>Detalles del Estudiante</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark  '}`}>
            {studentDetails.student_details.length > 0 ?                
                studentDetails.student_details.map((detail) => (
                    <div key={detail.id}>
                        <div className={`card m-4 ${theme === 'dark' ? 'bg-dark border-secondary text-white' : 'bg-light text-dark  '}`}>
                            <p className='m-2 text-pre-wrap' ><strong>Nicho:</strong> {detail.niche}</p>
                        </div>
                        <div className={`card m-4 ${theme === 'dark' ? 'bg-dark border-secondary text-white' : 'bg-light text-dark  '}`}>
                            <p className='m-2 text-pre-wrap' ><strong>Script:</strong> {detail.script}</p>
                        </div>
                        <div className={`card m-4 ${theme === 'dark' ? 'bg-dark border-secondary text-white' : 'bg-light text-dark  '}`}>
                            <p className='m-2 text-pre-wrap' ><strong>Oferta de servicio:</strong> {detail.service_offer}</p>
                        </div>
                        <div className={`card m-4 ${theme === 'dark' ? 'bg-dark border-secondary text-white' : 'bg-light text-dark  '}`}>
                            <p className='m-2 text-pre-wrap' ><strong>País de prospección:</strong> {detail.prosp_country}</p>
                        </div>
                        <div className={`card m-4 ${theme === 'dark' ? 'bg-dark border-secondary text-white' : 'bg-light text-dark  '}`}>
                            <p className='m-2 text-pre-wrap' ><strong>Observaciones del alumno:</strong> {detail.observations}</p>
                        </div>
                    </div>
                ))
                : <p className='text-warning m-4'>Sin información del estudiante, ¿ya completo su perfil?</p>
                } 
                <hr className='hr'/>
                <h3 className='h3 m-4'>Observaciones</h3>
                {studentDetails.observations && studentDetails.observations.length > 0 ? (
                    <Table striped bordered hover variant={theme === 'dark' ? 'dark' : 'light'}
                    className='mb-4'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Observación</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentDetails.observations.map((observation) => (
                                <tr key={observation.id}>
                                    <td>{observation.date.toString().split('.')[0].replace('T', ' ')}</td>
                                    <td className='text-pre-wrap'>
                                        {editingObservation === observation.id ? (
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={editedObservationText}
                                                onChange={(e) => setEditedObservationText(e.target.value)}
                                            />
                                        ) : (
                                            <p>{observation.observations}</p>
                                        )}
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-around">
                                            {editingObservation === observation.id ? (
                                                <>
                                                    <Button 
                                                        variant="primary"
                                                        onClick={handleSaveEditedObservation}>Guardar</Button>
                                                    <Button 
                                                        variant="danger"
                                                        onClick={stopEditObservation}>Cancelar</Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button 
                                                        variant="warning"
                                                        onClick={() => startEditObservation(observation)}
                                                        className={theme === 'dark' ? ' text-white' : 'text-dark'}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                    <Button 
                                                        variant="danger"
                                                        onClick={() => handleDeleteObservation(observation.id)}
                                                        className={theme === 'dark' ? ' text-white' : 'text-dark'}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    ) : (
                        <p className='text-pre-wrap'>No hay observaciones.</p>
                    )}


                <Form.Group className='m-2'>
                    <Form.Label>Agregar observación</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={newObservation}
                        onChange={(e) => setNewObservation(e.target.value)}
                        disabled={loading ? true : false}
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className={`custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark '}`}>
                <Button variant="primary" onClick={handleSubmit} disabled={loading ? true : false}>
                    Guardar observación
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default StudentDetailsModal;

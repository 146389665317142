import React, { useState, useEffect } from 'react';
import CalendarSelector from './CalendarSelector';
import { useSummary } from './SummaryContext';
import axios from 'axios';
import { PROSPECTION_URL, SUMMARY_URL } from '../apiConfig';
import './Prospeccion.css'; // Importar el archivo CSS para estilizar

const ProspeccionModal = ({ onClose, onClientChange, startDate, endDate, studentData, setStudentData, loading }) => {
    const {  selectedDate, setSelectedDate } = useSummary();
    const [successMessage, setSuccessMessage] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    // const [loading, setLoading] = useState(false); // Estado de carga
    const [error, setError] = useState(null); // Estado de error

    const [listasTiempo, setListasTiempo] = useState(0);
    const [listasCantidad, setListasCantidad] = useState(0);
    const [contactosParte1, setContactosParte1] = useState(0);
    const [contactosParte2, setContactosParte2] = useState(0);
    const [contactosParte2Respondida, setContactosParte2Respondida] = useState(0);
    const [reunionesRealizadas, setReunionesRealizadas] = useState(0);
    const [seguimientoRealizado, setSeguimientoRealizado] = useState(false);
    const [date2, setdate2] = useState(new Date()); // Estado de error
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    const prospectionData = studentData?.prospection || [];

    useEffect(() => {
        const selectedDateStr = selectedDate.toISOString().split('T')[0]; // Convertir la fecha seleccionada al formato 'YYYY-MM-DD'
        // Buscar los datos de prospección para la fecha seleccionada
        const prospectionDataForDate = prospectionData.find(item => item.date === selectedDateStr);

        if (!prospectionDataForDate) {
            setContactosParte1(0);
            setContactosParte2(0);
            setContactosParte2Respondida(0);
            setReunionesRealizadas(0);
            setSeguimientoRealizado(false);
            setError('No hay datos para la fecha seleccionada.');
        } else {
            console.log(prospectionDataForDate)
            setdate2(selectedDateStr)
            setContactosParte1(prospectionDataForDate.contactos_parte_1_enviada || 0);
            setContactosParte2(prospectionDataForDate.contactos_parte_2_enviada || 0);
            setContactosParte2Respondida(prospectionDataForDate.contactos_parte_2_respondida || 0);
            setReunionesRealizadas(prospectionDataForDate.reuniones_realizadas || 0);
            setSeguimientoRealizado(prospectionDataForDate.seguimiento_realizado || false);
            setError(null);
        }
    }, [selectedDate, prospectionData]);

    const handleDateChange = (date) => {
        setSuccessMessage(null);
        setSelectedDate(date);
        localStorage.setItem('selectedDate', date.toISOString());
    };

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const isTodayOrYesterday = (date) => {
        const selected = new Date(date.toDateString());
        return selected.getTime() === today.setHours(0, 0, 0, 0) || selected.getTime() === yesterday.setHours(0, 0, 0, 0);
    };

    const handleSubmit = async () => {
        setSaveLoading(true);
        setSuccessMessage(null);

        const token = localStorage.getItem('token');
        const headers = { Authorization: `Token ${token}` };

        try {
            await axios.post(PROSPECTION_URL, {
                listas_tiempo_invertido: listasTiempo,
                listas_cantidad_agregados: listasCantidad,
                contactos_parte_1_enviada: contactosParte1,
                contactos_parte_2_enviada: contactosParte2,
                contactos_parte_2_respondida:contactosParte2Respondida,
                reuniones_realizadas: reunionesRealizadas,
                seguimiento_realizado: seguimientoRealizado,
                date: selectedDate.toISOString().split('T')[0],
            }, { headers });
            setSuccessMessage('Datos guardados exitosamente.');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized, removing token');
                localStorage.removeItem('token'); // Eliminar token si es un 401
                alert('Debes iniciar sesión nuevamente.');
                window.location.href = '/'; // Redirigir a /login
        
            } else {
            console.error('Error al guardar los datos:', error);
        }} finally {
            const response = await axios.get(SUMMARY_URL, {
                headers,
                params: {
                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0],
                },
            });
            setStudentData(response.data);
            setSaveLoading(false);
        }
    };
    const handleClickOutside = (e) => {
        if (e.target.classList.contains('modal')) {
          onClose();
        }
      };
    return (
        <div className="modal" onClick={handleClickOutside}>
            <div className={`p-4 rounded w-50 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>

                <span className="close" onClick={onClose}>&times;</span>

                <h1 className={`h1 ${theme === "light" ? "text-black" : "text-white"}`}>Prospección</h1>
                <div className="calendar-section">
                    <h3>Seleccionar Fecha</h3>
                    <CalendarSelector 
                    initialDate={selectedDate} 
                    onDateChange={handleDateChange} 
                    />
                </div>

                {loading && <p className="loading-text">Cargando...</p>}
                {error && <p className="error-text">{error}</p>}
                {successMessage && <p className="success-text">{successMessage}</p>}

                {prospectionData && (
                    <div className={`prospection-data ${saveLoading ? 'disabled' : ''}`}>
                        
                        <div>
                            <h3>Mensajes enviados</h3>
                            <h5>Primera parte enviada</h5>
                            <input
                                type="number"
                                value={contactosParte1}
                                onChange={(e) => setContactosParte1(parseInt(e.target.value, 10))}
                                readOnly={!isTodayOrYesterday(selectedDate)}
                                className={`input ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}

                            />
                            <h5>Segunda parte enviada</h5>
                            <input
                                type="number"
                                value={contactosParte2}
                                onChange={(e) => setContactosParte2(parseInt(e.target.value, 10))}
                                readOnly={!isTodayOrYesterday(selectedDate)}
                                className={`input ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}

                            />
                            <h5>Segunda parte respondida</h5>
                            <input
                                type="number"
                                value={contactosParte2Respondida}
                                onChange={(e) => setContactosParte2Respondida(parseInt(e.target.value, 10))}
                                readOnly={!isTodayOrYesterday(selectedDate)}
                                className={`input ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}

                            />
                            <h5>Reuniones Realizadas</h5>
                            <input
                                type="number"
                                value={reunionesRealizadas}
                                onChange={(e) => setReunionesRealizadas(parseInt(e.target.value, 10))}
                                readOnly={!isTodayOrYesterday(selectedDate)}
                                className={`input ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}

                            />
                        </div>

                        <div className="checkbox-section">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={seguimientoRealizado}
                                    onChange={(e) => setSeguimientoRealizado(e.target.checked)}
                                    readOnly={!isTodayOrYesterday(selectedDate)}
                                    data-bs-theme={`${theme === "light" ? "light" : "dark"}`}

                                />
                                Realizaste seguimiento a los prospectos pendientes?
                            </label>
                        </div>
                    </div>
                )}

                <button 
                    onClick={handleSubmit} 
                    disabled={!isTodayOrYesterday(selectedDate) || loading || saveLoading} 
                    className="primary-btn"
                >
                    {saveLoading ? <div className="spinner"></div> : 'Guardar'}
                </button>
                <button className="secondary-btn" onClick={onClose}>Cerrar</button>

            </div>
        </div>
    );
};

export default ProspeccionModal;
